import {GAME_LOADING, GAME_OVER, GAME_TRANSITION, PLAY_GAME, POP_BALLON, PRIZE_PRELOADER} from "../actions/ballon-game";

export const initialState = {
    inGame: false,
    isGameLoading: false,
    isGameOver: false,
    isTransition: false,
    isPrizePreloader: false,
    count: 0
}

export const ballonGameReducer = (state = initialState, action) => {
    switch (action.type) {
        case PLAY_GAME: {
            return {
                ...state,
                inGame: true,
                isGameOver: false,
                isGameLoading: false,
                isTransition: false,
                isPrizePreloader: false
            }
        }
        case GAME_OVER: {
            return {
                ...state,
                inGame: false,
                isGameOver: true,
                isGameLoading: false,
                isTransition: false,
                count: 0,
                isPrizePreloader: false
            }
        }
        case POP_BALLON: {
            return {
                ...state,
                count: action.count
            }
        }
        case GAME_LOADING: {
            return {
                ...state,
                inGame: false,
                isGameOver: false,
                isGameLoading: true,
                isTransition: false,
                isPrizePreloader: false
            }
        }
        case GAME_TRANSITION: {
            return {
                ...state,
                inGame: false,
                isGameOver: false,
                isGameLoading: false,
                isTransition: true,
                isPrizePreloader: false
            }
        }
        case PRIZE_PRELOADER: {
            return {
                ...state,
                isPrizePreloader: true
            }
        }
        default: {
            return state;
        }
    }
}