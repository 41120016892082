import "./canvas.css";
import { useRef, useEffect, useState } from "react";
import frameRenderer from "./frameRenderer";

let mainMatrix = [];
const str = process.env.REACT_APP_MESSAGE_SOS;
const matrix = str.split('');


function Canvas() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const canvasRef = useRef(null);
  let W = (canvasRef.width = window.innerWidth);
  let H = (canvasRef.height = window.innerHeight);
  let yPositions = Array(300).join(0).split('');
  const size = { width: W, height: H };

  mainMatrix = matrix;

  const renderFrame = () => {
    const ctx = canvasRef.current.getContext("2d");
    const grd = ctx.createRadialGradient(W/2, H/2, 50, W/2, H/2, W);
    grd.addColorStop(0, "rgba(0,42,42,0.1)");
    grd.addColorStop(1, "rgba(0,0,0,0.2)");

    frameRenderer.call(ctx, size, yPositions, mainMatrix, grd);
  };

  const runAnim = () => {
    renderFrame();
  }

  useEffect(() => {
    const interval = setInterval(runAnim, 50);
  
    return () => clearInterval(interval);
  }, [runAnim]);


  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const buttonHandle = () => {
    window.open('https://spb.docdoc.ru/doctor/lor_otolaringolog', '_blank');
  }


  return (
      <div className={"matrix-canvas"}>
        <div className={"scanline"}></div>
        <canvas className={"matrix-canvas"} {...size} ref={canvasRef} onClick={buttonHandle}/>
      </div>
  )
}

export default Canvas;
