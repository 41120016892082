import Canvas from "../canvas/canvas";
import {Route, Routes} from "react-router-dom";
import {Protect} from "../protect/protect";

function App() {

  return (
      <div id="container">
          <Routes>
              <Route path={"*"} element={<Protect />} />
              <Route path={"/"} element={<Canvas />} />
          </Routes>
      </div>
  );
}

export default App;

