function frameRenderer(size, yPositions, matrix, grd) {
    //this.clearRect(0, 0, size.width, size.height);

    const draw = () => {
        this.fillStyle = grd; //"rgba(0,0,0,.1)";
        this.fillRect(0, 0, size.width, size.height);
        this.fillStyle = "#0f0";
        this.font = '14pt Inconsolata';

        let j = 0;

        for (let i = 0; i < yPositions.length; i++) {
            let text = matrix[Math.floor(Math.random() * matrix.length)];
            let x = (i * 14) + 14;
            let y = yPositions[i];
            this.fillStyle = `hsl(${180 + i * 120}, 80%, 60%)`
            this.fillText(text, x, y);
            if (y > 200 + Math.random() * 1e4) {
                yPositions[i] = 0;
            } else {
                yPositions[i] = y + 20;
            }
            j++;
            if (j >= matrix.length){
                j = 0;
            }
        }
      };
  
    draw();
  }
  
  export default frameRenderer;
  
  